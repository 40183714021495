/* Instalar BIBLIOTECAS:
npm install --save react-elastic-carousel
npm install --save styled-components

*/


import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from "react-dom";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import styles from "./Carrossel.module.css";
import Videos from './Videos';
import PropTypes from "prop-types";


const breakPoints = [
  { width: 1, itemsToShow: 1 },
  // { width: 550, itemsToShow: 2 },
  // { width: 768, itemsToShow: 3 },
  // { width: 1200, itemsToShow: 4 },
];

const customArrow = ({ type, onClick, isEdge }) => {
  const label = type === 'PREV' ? 'Anterior' : 'Próximo';

  return (
    <button
      onClick={onClick}
      disabled={isEdge}
      style={{
        fontSize: '30px',
        cursor: 'pointer',
        color: '#ffffff', 
        outline: 'none', 
        border: 'none', 
        background: 'transparent', 
        width: '.4em',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '.8em',

      }}
      aria-label={label}
    >
      {type === 'PREV' ? '❮' : '❯'}
    </button>
  );
};


function Carrossel({ titlepage, items, texto }) {

  const [isVisible, setIsVisible] = useState(false);
  const carrosselRef = useRef(null);


  const dotStyles = {
    backgroundColor: '#000000', // Cor das dots inativas
    width: '10px',
    height: '10px',
    margin: '0 5px',
    borderRadius: '50%',
    cursor: 'pointer',
  };

  const activeDotStyles = {
    backgroundColor: '#00ff00', // Cor da dot ativa
    width: '10px',
    height: '10px',
    margin: '0 5px',
    borderRadius: '50%',
    cursor: 'pointer',
  };

  const handleScroll = () => {
    if (carrosselRef.current) {
      const rect = carrosselRef.current.getBoundingClientRect();
      setIsVisible(rect.top < window.innerHeight && rect.bottom >= 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    <>

      <div ref={carrosselRef} className={styles.carrossel}>



        <div className={`${styles.divvideo} ${isVisible && styles.appear}`}>



           <div className={styles.divtitle}>
            <p className={`${styles.titlecarousel} ${isVisible && styles.appear2}`}>
              {titlepage}
              <p className={styles.texto}>{texto}</p>
            </p>
          </div> 

          <div className={styles.divvideo}>
            <Carousel
              breakPoints={breakPoints}
              renderArrow={customArrow}
              className={styles.customCarousel}
              renderPagination={({ pages, activePage, onClick }) => (
                <div>
                  {pages.map((page, index) => (
                    <span
                      key={index}
                      onClick={() => onClick(index)}
                      style={index === activePage ? activeDotStyles : dotStyles}
                    />
                  ))}
                </div>
              )}
            >
              {items.map((item, index) => (
                <Item key={index}>
                  <Videos
                    icon={item.icon1}
                    link={item.link1}
                    titlecard={item.titlecard1}
                  />
                </Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>

    </>

  )
};


Carrossel.propTypes = {
  titlepage: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Carrossel;


