import Fdistudios from './components/index/Fdistudios'
import Banner from './components/index/Banner';
import Afabrica from './components/index/Afabrica'
import Municine from './components/index/Municine';
import Documentarios from './components/index/Documentarios'
import Storytelling from './components/index/Storytelling';
import Faleconosco from './components/index/Faleconosco';
import Footer from './components/index/Footer';
import Artes from './components/index/Artes';
import Barra from './components/index/Barra';

function App() {

  return (
    <>
      <Barra />
      <Banner />
      <Fdistudios />
      <Afabrica />
      <Storytelling />
      <Documentarios />
      <Municine />
      <Artes />
      <Faleconosco />
      <Footer />
    </>

  )
};

export default App;
