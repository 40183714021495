import React from "react";
import styles from './Afabrica.module.css'
import foto1 from './/..//..//img/icones/foco.png'
import foto2 from './/..//..//img/icones/inspiracao.png'
import foto3 from './/..//..//img/icones/oquefazemos.png'
import foto4 from './/..//..//img/icones/produto.png'



function Afabrica() {

    return (

            <section className={styles.afabrica}>

                <div className={styles.containerafabrica}>

                    <img className={styles.icon} src={foto1} />
                    <p className={styles.title}>Nosso Foco:</p>
                    <br />
                    <p className={styles.text}>O estudo do ancestral do cinema: o teatro de sombras.</p>
                    <br /> <br />
                    

                    <img className={styles.icon} src={foto2} />
                    <p className={styles.title}>Inspirações:</p><br />
                    <p className={styles.text}>Expressão de Rua, Cultura popular, povos originários.</p>
                    <br /> <br />

                    <img className={styles.icon} src={foto3} />
                    <p className={styles.title}>O que fazemos?</p><br />
                    <p className={styles.text}>Abraçamos a inclusão digital e a tecnologia social das produtoras culturais colaborativas. Dentro do processo de cocriação, compartilhamos recursos, protagonismos, autonomias e dentro deste processo nossa equipe consegue realizar projetos autorais com recursos limitados, conferindo identidade às nossas produções.</p>
                    <br /> <br />

                    <img className={styles.icon} src={foto4} />
                    <p className={styles.title}>Produtos:</p> <br />
                    <p className={styles.text}>Produção documental voltada para o terceiro setor;<br />
                        Esculturas em madeira e outros materiais;<br />
                        Cenografias;<br />
                        Oficinas de cinema sobre a perspectiva da educação popular "MUNICINE: Formação Livre em Audiovisual" (Parceria UFRN).
                    </p>

                </div>

            </section>

    )
}

export default Afabrica