import React from "react";
import Carrossel from './Carrossel'
import styles from './Storytelling.module.css'

import capacordeldavila from './..//..//img/capas/capacordedavila.jpg'
import capacabaca from './/..//..//img/capas/capacabaca1080.jpg'
import foto1 from './/..//..//img/storytelling/1.jpg'
import fotocordel1 from './/..//..//img/storytelling/cordel1.jpeg'
import foto2 from './/..//..//img/storytelling/2.jpg'
import foto3 from './/..//..//img/storytelling/3.jpg'
import foto4 from './/..//..//img/storytelling/4.jpg'
import foto5 from './/..//..//img/storytelling/5.jpg'
import foto6 from './/..//..//img/storytelling/6.jpg'
import foto7 from './/..//..//img/storytelling/7.jpg'
import foto8 from './/..//..//img/storytelling/8.jpg'
import foto9 from './/..//..//img/storytelling/9.jpg'
import foto10 from './/..//..//img/storytelling/10.jpg'
import fotocabaca from './/..//..//img/storytelling/cabaca1.jpeg'
import fotocabaca2 from './/..//..//img/storytelling/cabaca2.jpg'
import fotocabaca3 from './/..//..//img/storytelling/cabaca3.jpg'
import fotocabaca4 from './/..//..//img/storytelling/cabaca4.jpg'

function Storytelling() {

  const titulo = "Storytelling"

  const itemsData = [
    {
      icon1: capacordeldavila,
      link1: "https://www.youtube.com/embed/EJgu17np2hs?si=LPMsSA6m5hS-PKi9",
      titlecard1: "Assista o Cordel da Vila de Ponta Negra",
    },
    {
      icon1: capacabaca,
      link1: "https://www.youtube.com/embed/S0zEFd2eb_E?si=rScnQQliEg-xA0Ka",
      titlecard1: "Assista A Cabaça da Criação",
    },
    {
      icon1: capacordeldavila,
      link1: foto1,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },
    {
      icon1: capacordeldavila,
      link1: fotocordel1,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },
    {
      icon1: capacordeldavila,
      link1: foto2,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },
    {
      icon1: capacordeldavila,
      link1: foto3,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },
    {
      icon1: capacordeldavila,
      link1: foto4,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },
    {
      icon1: capacordeldavila,
      link1: foto5,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },
    {
      icon1: capacordeldavila,
      link1: foto6,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },
    {
      icon1: capacordeldavila,
      link1: foto7,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },
    {
      icon1: capacordeldavila,
      link1: foto8,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },
    {
      icon1: capacordeldavila,
      link1: foto9,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },
    {
      icon1:capacordeldavila,
      link1: foto10,
      titlecard1: "Processo de Produção do Cordel da Vila de Ponta Negra",
    },   
    {
      icon1:capacabaca,
      link1: fotocabaca,
      titlecard1: "Processo de Produção da Cabaça da Criação",
    },
    {
      icon1:capacabaca,
      link1: fotocabaca2,
      titlecard1: "Processo de Produção da Cabaça da Criação",
    },
    {
      icon1:capacabaca,
      link1: fotocabaca3,
      titlecard1: "Processo de Produção da Cabaça da Criação",
    },
    {
      icon1:capacabaca,
      link1: fotocabaca4,
      titlecard1: "Processo de Produção da Cabaça da Criação",
    },

  ];


  return (

      <div className={styles.storytelling}>

        <Carrossel titlepage={titulo} items={itemsData}/>

      </div>

  )
}

export default Storytelling

// const rootElement = document.getElementById("root");
// ReactDOM.render(<Carrossel />, rootElement);