import React from "react";
import Carrossel from './Carrossel'
import styles from './Documentarios.module.css'

import logofabrica from './../../img/fabricadeinventos2.png'
import foto1 from './../../img/documentarios/1.jpg'


function Documentarios() {

  const titulo = "Documentários"

  const itemsData = [
    
    {
      icon1: logofabrica,
      link1: "https://www.youtube.com/embed/LukMxyBRwP4?si=nVybDmZzSPM9Axkd",
      titlecard1: "Projeto Saberes Sustentáveis apresenta Mestre Pedro",
    },
    {
      icon1: logofabrica,
      link1: "https://www.youtube.com/embed/KMTCkQX5FLo?si=BkgU2TWiiH2HS272",
      titlecard1: "Cordel no Coco",
    },
    {
      icon1: logofabrica,
      link1: "https://www.youtube.com/embed/cy24QDPhI04?si=47xBo_6GM2OZvRzy",
      titlecard1: "Projeto Saberes Sustentáveis apresenta Mestre Arraia",
    },
    {
      icon1: logofabrica,
      link1: "https://www.youtube.com/embed/kRJy2foeueA?si=xYb-fVZPgOvArgRt",
      titlecard1: "Um dia na Fábrica de Inventos",
    },
    {
    icon1: logofabrica,
    link1: "https://www.youtube.com/embed/6TLStGVaImg?si=-fQou9WZb-sAIFiH",
    titlecard1: "alimentação indígena no Seridó e serra de Santana",
    },
    {
      icon1: logofabrica,
      link1: foto1,
      titlecard1: "",
      },
  ];


  return (

      <div className={styles.documentarios}>

        <Carrossel titlepage={titulo} items={itemsData} />

      </div>

  )
}

export default Documentarios
