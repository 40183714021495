import React from "react";
import styles from './Faleconosco.module.css'
import emailjs from '@emailjs/browser'
import { useState } from 'react'
import { useRef } from 'react'


function Faleconosco() {

    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    const formRef = useRef('')

    const handleFormReset = () => {
        formRef.current.reset();
    };

    function sendEmail(e) {

        e.preventDefault();
        if (name === '' || mail === '' || message === '') {
            alert("Por favor, preencha todos os campos obrigatórios!")
            return;
        }


        const templateParams = {
            name: name,
            mail: mail,
            phone: phone,
            message: message
        }

        emailjs.send("service_tou9i8h", "template_lq5ma2t", templateParams, "vmjjD7jPTH6uDWESq")
            .then((response) => {
                console.log("Email enviado", response.status, response.text)
                setName('')
                setMail('')
                setPhone('')
                setMessage('')

            }, (err) => {
                console.log("ERRO ", err)
            })
        alert("Sua mensagem foi enviada. Obrigado!")
        formRef.current.reset();

    }



    return (



        <section className={styles.faleconosco}>

            <div className={styles.containerfaleconosco}>

                <div className={styles.containerleft}>
                    <p className={styles.title}>fale com a Fábrica </p>
                </div>

                <div className={styles.containerright}>

                    <form ref={formRef} onSubmit={sendEmail}>

                        <div className={styles.form}>

                            <input type="hidden" name="subject" value="Mensagem enviada pelo site da Fábrica de inventos" />

                            <label for='name' className={styles.fontcontact}>Nome:</label>
                            <input
                                className={styles.inputs}
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />

                            <label for='mail' type='email' className={styles.fontcontact}> Email:</label>
                            <input
                                className={styles.inputs}
                                type="text"
                                onChange={(e) => setMail(e.target.value)}
                                value={mail}
                            />

                            <label for='phone' className={styles.fontcontact}> Telefone(Opcional): </label>
                            <input
                                className={styles.inputs}
                                type="text"
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                            />

                            <label for='message' className={styles.fontcontact}>Mensagem:</label>
                            <textarea
                                className={styles.contactmessage}
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                            />
            
                            <div className={styles.buttonscontainer}>

                                    <button className={styles.buttoncontactform} type='submit'>
                                        <p className={styles.buttontext}> Enviar</p>
                                    </button>

                                </div>

                        </div>

                    </form>

                </div>

            </div>

        </section>


    )
}

export default Faleconosco 