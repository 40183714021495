import { FaFacebook, FaInstagram, FaPhone, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import styles from "./Barra.module.css";




function Barra() {

  return (

    <div className={styles.barra}>

      <div className={styles.esquerda}>
        <p className={styles.text}><FaPhone className={styles.socialmedias} />(84) 9 9973-6147</p>
      </div>

      <div className={styles.direita}>

        <a href="https://www.facebook.com/afabricadeinventos/" target="_blank">
          <FaFacebook className={styles.socialmedias} />
        </a>

        <a href="https://www.instagram.com/afabricadeinventos/" target="_blank">
          <FaInstagram className={styles.socialmedias} />
        </a>

        <a href="https://w.app/FDISTUDIOS" target="_blank">
          <FaWhatsapp className={styles.socialmedias} />
        </a>

        <a href="https://www.youtube.com/@macunaima1000/videos" target="_blank">
          <FaYoutube className={styles.socialmedias} />
        </a>

        {/* <p className={styles.socialmedias2}> contato@fabricadeinventos.com.br</p> */}

      </div>

    </div>

  )

}

export default Barra