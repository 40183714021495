/* INSTALAR REACT ICONS:
npm install react-icons --save
*/
import styles from './Footer.module.css'
import { FaFacebook, FaRegEnvelope, FaInstagram, FaPhone, FaWhatsapp, FaYoutube, FaMapMarkerAlt } from 'react-icons/fa'


import logofdi from './/..//..//img/fdistudiobranca.png'
import logofabrica from './/..//..//img/fabricadeinventosbranca.png'
import logomc from './/..//..//img/logomc.png'
import logokina from './/..//..//img/logokina.png'
import logocirs from './/..//..//img/LOGOCIRSBranca.png'
import logoufrn from './/..//..//img/logoufrnbranca.png'
import logoppgas from './/..//..//img/PPGASLOGOp.png'
import licenca from './/..//..//img/icones/by-nc-nd.png'

function Footer() {
   return (


      <section className={styles.footersection}>

         <div className={styles.footer}>

            <div className={styles.logos}>

               <img className={styles.logofabrica} source src={logofabrica} />
               {/* <img className={styles.logofdi} source src={logofdi} /> */}

            </div>

            <div className={styles.divscolumn}>

               <p className={styles.title}>Contato:</p>

               <div className={styles.divicones}>

                  <p className={styles.text}> <FaPhone /> 84 9 9973-6147</p>
                  <p className={styles.text}> <FaRegEnvelope /> contato@fabricadeinventos.com.br</p>
                  <p className={styles.text}> <FaMapMarkerAlt /> R. da Campína, 26 - Vila de Ponta Negra, Natal - RN, 59090-480</p>

               </div>

            </div>

            <div className={styles.divspadrao}>

               <p className={styles.title}>Redes Sociais:</p>

               <div className={styles.divsredessocias}>

                  <a href="https://www.facebook.com/afabricadeinventos/" target="_blank">
                     <FaFacebook className={styles.socialmedias} />
                  </a>

                  <a href="https://www.instagram.com/afabricadeinventos/" target="_blank">
                     <FaInstagram className={styles.socialmedias} />
                  </a>

                  <a href="https://w.app/FDISTUDIOS" target="_blank">
                     <FaWhatsapp className={styles.socialmedias} />
                  </a>

                  <a href="https://www.youtube.com/@macunaima1000/videos" target="_blank">
                     <FaYoutube className={styles.socialmedias} />
                  </a>

               </div>

            </div>

            <div className={styles.divscolumn}>

               <p className={styles.title}>Parcerias: </p>

               <div className={styles.logos}>

                  <img className={styles.logomc} source src={logomc} />
                  <img className={styles.logokina} source src={logokina} />
                  <img className={styles.logoufrn} source src={logoufrn} />
                  <img className={styles.logocirs} source src={logocirs} />
                  <img className={styles.logoppgas} source src={logoppgas} />


               </div>

            </div>

            <br />
            {/* <p className={styles.text}>©2015 - Fábrica de Inventos - Todos os direitos reservados.</p> */}
            <img className={styles.iconecc} source src={licenca} />
            <br />
         </div>
         <br />

      </section>

   )
}

export default Footer