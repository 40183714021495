import styled from "styled-components";

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 10%;
  background-color: transparent;
  color: #fff;
  margin-bottom: 0px;
  font-size: 4em;
  border-radius: 15px;
`;

export default Item;