import React from 'react';
import PropTypes from 'prop-types';
import styles from './Videos.module.css';

function Videos({ icon, link, titlecard }) {
  const isVideo = link.includes('youtube.com') || link.includes('vimeo.com');

  

  return (
    <section className={styles.videoscontainer}>
      <div className={styles.cardcontainer}>
        <div className={styles.card}>
          <div className={styles.front}>
            <img className={styles.iconsimg} src={icon} alt="Icon" />
          </div>
          <div className={styles.back}>
            {isVideo ? (
              <iframe
                className={styles.videos}
                src={link}
                title="A Fábrica de Inventos"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            ) : (
              <img className={styles.image} src={link} alt="Image" />
            )}
          </div>
        </div>
        <p className={styles.textfont}> {titlecard}</p>
      </div>
    </section>
    
  );
}

Videos.propTypes = {
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  titlecard: PropTypes.string.isRequired,
};

export default Videos;