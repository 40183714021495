import React from "react";
import Carrossel from "./Carrossel";
import capamunicine from './/..//..//img/capas/capamunicine1080.jpg'
import styles from './Municine.module.css'
import capacabaca from './/..//..//img/capas/capacabaca1080.jpg'
import foto1 from './/..//..//img/municine/1.jpg'
import foto2 from './/..//..//img/municine/2.jpg'
import foto3 from './/..//..//img/municine/3.jpg'
import foto4 from './/..//..//img/municine/4.jpg'
import foto5 from './/..//..//img/municine/5.jpg'
import foto6 from './/..//..//img/municine/6.jpg'
import foto7 from './/..//..//img/municine/7.jpg'
import foto8 from './/..//..//img/municine/8.jpg'


function Municine() {

    const titulo = "Municine"

    const itemsData = [

        {
            icon1: capamunicine,
            link1: foto1,
            titlecard1: "",
        },
        {
            icon1: capamunicine,
            link1: foto2,
            titlecard1: "",
        },
        {
            icon1: capamunicine,
            link1: foto3,
            titlecard1: "",
        },
        {
            icon1: capamunicine,
            link1: foto4,
            titlecard1: "",
        },
        {
            icon1: capamunicine,
            link1: foto5,
            titlecard1: "",
        },
        {
            icon1: capamunicine,
            link1: foto6,
            titlecard1: "",
        },
        {
            icon1: capamunicine,
            link1: foto7,
            titlecard1: "",
        },
        {
            icon1: capamunicine,
            link1: foto8,
            titlecard1: "",
        },
    ];

    return (

        <>
            <div className={styles.municine}>
                <Carrossel titlepage={titulo} items={itemsData} />
            </div>
        </>

    )

}

export default Municine
