import React from "react";
import styles from './Fdistudios.module.css'
import video from './../../img/vinhetalogofdi.mp4'


function Fdistudios() {

    return (

        <section className={styles.fdistudios}>

            <div className={styles.fdicontainer}>

                <div className={styles.videocontainer}>
                    <video className={styles.logo} autoPlay muted loop type="video/mp4">
                        <source className={styles.movie} src={video} />
                    </video>

                </div>


                <div className={styles.textcontainer}>

                    <p className={styles.text}> A FDISTUDIOS é uma produtora audiovisual que abraça a colaboração como pilar fundamental de sua missão. Em parceria com artistas de diversas áreas, desenvolvemos o laboratório de teatro de sombras e animação a fim de colocar o audiovisual no centro de nossa busca incessante por explorar fronteiras artísticas. Acreditamos que a economia colaborativa é a estratégia essencial para impulsionar nossa criatividade e excelência.<br />
                    </p>
                    
                    <p className={styles.text2}>
                        O coração pulsante desse projeto é a oralidade, um dispositivo que transcende as barreiras tradicionais da comunicação. Através da voz e das histórias compartilhadas, construímos narrativas profundas e impactantes, dando vida a personagens e culturas muitas vezes esquecidos.<br />
                    </p>

                    <p className={styles.text2}>No âmago da proposta da FDISTUDIOS, reside a convicção de que a verdadeira magia do teatro de sombras e animação está na capacidade de revelar histórias autênticas, pintando com luz e sombra as experiências que moldam nossa existência. Nossa instituição firma seu compromisso de continuar desafiando as fronteiras da expressão artística e oferecendo uma janela única para a riqueza da diversidade humana.</p>

            </div>


        </div>

        </section >

    )

}

export default Fdistudios