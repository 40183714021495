import React, { useEffect, useRef, useState } from 'react';
import styles from './Banner.module.css'
import video from '..//..//..//src/img/fabrica_mobile.mp4'
import logo from './../../img/fabricadeinventosbranca.png'



function Banner() {

  const [isVisible, setIsVisible] = useState(false);
  const textRef = useRef(null);

  const handleScroll = () => {
    if (textRef.current) {
      const rect = textRef.current.getBoundingClientRect();
      setIsVisible(rect.top < window.innerHeight && rect.bottom >= 0);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  return (

    <section ref={textRef} className={styles.banner}>

      <div className={styles.divtitle}>
        <img className={styles.img} src={logo}></img>
      </div>

      <div className={styles.divmain}>

        <div className={styles.containervideo}>
          <video className={styles.videodiv} autoPlay muted loop type="video/mp4">
            <source className={styles.movie} src={video} />
          </video>
        </div>

        <p ref={textRef} className={`${styles.text} ${isVisible ? styles.appear : ''}`}>

        A Fábrica de Inventos é uma produtora cultural colaborativa engajada com audiovisual e linguagens urbanas, reunindo fazedores da cultura de diversas áreas. Em um organismo autônomo e independente, desenvolvemos a linguagem da animação e o hibridismo com o “Cordel da vila: a rainha louca contra o escandaloso” e “A cabaça da criação". E busca de um cinema ritual e de invenção em que o retorno para a sociedade se caracteriza em exibições em terreiros e comunidades e oficinas formativas como ”MUNICINE” oficinas livres de introdução a linguagem audiovisual (parceria com Dep. de Antropologia da UFRN, projeto:" Saberes Sustentáveis").
        </p>



      </div>



    </section >

  )
}

export default Banner