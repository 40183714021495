import React from "react";
import Carrossel from './Carrossel'
import styles from './Artes.module.css'

import logofabrica from './../../img/fabricadeinventos2.png'
import foto1 from './../../img/artes/1.jpg'
import foto2 from './../../img/artes/2.jpg'
import foto3 from './../../img/artes/3.jpg'
import foto4 from './../../img/artes/4.jpg'
import foto5 from './../../img/artes/5.jpg'
import foto6 from './../../img/artes/6.jpg'
import foto7 from './../../img/artes/7.jpg'
import foto8 from './../../img/artes/8.jpg'
import foto9 from './../../img/artes/9.jpg'
import foto10 from './../../img/artes/10.jpg'
import foto11 from './../../img/artes/11.jpg'
import foto12 from './../../img/artes/12.jpg'
import foto13 from './../../img/artes/13.jpg'


function Artes() {

  const titulo = "Artes"
  const texto = 'A produção coletiva mais recente pode ser vista em cinco esculturas grandes de madeira feita pela “Omorode Crew”  que se encontram no mercado de comercialização de produtos da agricultura familiar.'

  const itemsData = [
    {
      icon1: logofabrica,
      link1: "https://www.youtube.com/embed/5xki6ELj7AM?si=6H4rediLq77t2IGU",
      titlecard1: "Mercado da Agricultura Familiar - Esculturas por @afabricadeinventos",
    },
    {
      icon1: foto1,
      link1: foto1,
      titlecard1: "",
    },
    {
      icon1: foto2,
      link1: foto2,
      titlecard1: "",
    },
    {
      icon1: foto3,
      link1: foto3,
      titlecard1: "",
    },
    {
      icon1: foto4,
      link1: foto4,
      titlecard1: "",
    },
    {
      icon1: foto5,
      link1: foto5,
      titlecard1: "",
    },
    {
      icon1: foto6,
      link1: foto6,
      titlecard1: "",
    },
    {
      icon1: foto7,
      link1: foto7,
      titlecard1: "",
    },
    {
      icon1: foto8,
      link1: foto8,
      titlecard1: "",
    },
    {
      icon1: foto9,
      link1: foto9,
      titlecard1: "",
    },
    {
      icon1: foto10,
      link1: foto10,
      titlecard1: "",
    },
    {
      icon1: foto11,
      link1: foto11,
      titlecard1: "",
    },
    {
      icon1: foto12,
      link1: foto12,
      titlecard1: "",
    },
    {
      icon1: foto13,
      link1: foto13,
      titlecard1: "",
    },

  ];


  return (

    <div className={styles.artes}>     
      <Carrossel titlepage={titulo} items={itemsData} texto={texto}/>
    </div>

  )
}

export default Artes

// const rootElement = document.getElementById("root");
// ReactDOM.render(<Carrossel />, rootElement);